import { ButtonBase, LinearProgress, Skeleton, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import AppearFromSide from "../animations/AppearFromSide"
import { useContext, useEffect, useRef, useState } from "react"
import {
  contestRules,
  defaultBoxShadow,
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import propicsRowImage from "../../assets/images/propics-row.png"
import { MainContext } from "../../controllers/main"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import Text from "../global/common/Text"
import {
  numberWithSeparators,
  scrollWindow,
  scrollWindowToTop,
} from "../../services/utils/utils"
import ChallengeProgressBar from "./common/ChallengeProgressBar"
import months from "../../services/config/months"
import LeaderboardListItem from "../performance/common/LeaderboardListItem"
import { UsersContext } from "../../controllers/users"
import { useNavigate } from "react-router-dom"
import Button from "../global/common/Button"
import SavingCard from "../actions/common/SavingCard"
import Appear from "../animations/Appear"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import AlertInfo from "../global/common/AlertInfo"
import Prize from "../../models/prize"
import lockWhiteIcon from "../../assets/icons/lock-white.svg"
import Challenge from "../../models/challenge"

const ChallengeDesktop = ({
  displayedChallenge,
  displayedSavings,
  displayedLeaderboardLoading,
  oldPrizes,
  prizes,
}: {
  displayedChallenge: Challenge
  displayedSavings: {
    co2Saving: number
    waterSaving: number
    energySaving: number
  } | null
  displayedLeaderboardLoading: boolean
  oldPrizes: Prize[]
  prizes: Prize[]
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    windowHeight,
    user,
    isChallengeOver,
    isOldChallenge,
    setIsOldChallenge,
  } = useContext(MainContext)
  const {
    leaderboard,
    leaderboardError,
    leaderboardNextToken,
    getLeaderboard,
  } = useContext(UsersContext)

  // what is challenge info alert
  const [challengeInfoAlertOpen, setChallengeInfoAlertOpen] =
    useState<boolean>(false)

  // leaderboard loading and next token refs
  const loadingRef = useRef<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const nextToken = useRef<string | null>(leaderboardNextToken)

  // update next token ref when state changes
  useEffect(() => {
    nextToken.current = leaderboardNextToken
  }, [leaderboardNextToken])

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1400 ? `calc(100% - ${footerHeight}px)` : 1400,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("challenge"),
          },
        ]}
        selectedItem={0}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <Stack
            style={{
              position: "relative",
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title
                fontSize={36}
                lineHeight="46px"
                component="h1"
                color={colors.primary}
                style={{ fontStyle: "italic" }}
              >
                {t("collective_challenge").toUpperCase()}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("help_achieve_common_goal")}
              </Text>
            </AppearFromSide>
            <Appear style={{ position: "absolute", right: 0 }} fade>
              <AvatarInfoButton
                title={t("what_is_the_challenge")}
                onClick={() => {
                  setChallengeInfoAlertOpen(true)
                }}
              />
            </Appear>
          </Stack>
          {/* content */}
          <div
            style={{
              width: "100%",
              marginTop: 117,
              marginBottom: 58,
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* countdown card */}
            {isChallengeOver && (
              <Appear
                style={{ width: "40%", position: "absolute", top: 0, right: 0 }}
              >
                <Stack
                  alignItems="center"
                  gap={2}
                  style={{
                    width: "100%",
                    backgroundColor: colors.primary,
                    borderRadius: 24,
                    paddingTop: 22,
                    paddingBottom: 22,
                  }}
                >
                  <div
                    className="center"
                    style={{
                      height: 25,
                      minHeight: 25,
                      paddingInline: 10,
                      borderRadius: 22,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <Text
                      fontSize={14}
                      fontWeight={600}
                      color={colors.primary}
                      style={{ marginTop: 4 }}
                    >
                      CHALLENGE TERMINATA
                    </Text>
                  </div>
                  <Text
                    fontSize={16}
                    fontWeight={400}
                    color={colors.textWhite}
                    style={{
                      fontStyle: "italic",
                      textTransform: "uppercase",
                      marginTop: 8,
                    }}
                  >
                    Nuova challenge
                  </Text>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <img
                      src={lockWhiteIcon}
                      style={{ width: 40, height: 40 }}
                      alt=""
                    />
                    <Title
                      color={colors.textWhite}
                      style={{ textTransform: "uppercase", marginTop: 8 }}
                    >
                      stay tuned
                    </Title>
                  </Stack>
                </Stack>
              </Appear>
            )}
            {/* challenge card */}
            <AppearFromSide
              fade
              delay={0.1}
              style={{ width: "48.57%", height: "auto" }}
            >
              <Stack alignItems="center" style={{ width: "100%" }}>
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: 46,
                    backgroundColor: colors.backgroundWhite,
                    boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                    borderRadius: 10,
                  }}
                >
                  <Title fontSize={36} lineHeight="46px" component="h2">
                    {displayedChallenge.title}
                  </Title>
                  <Title
                    fontSize={24}
                    lineHeight="46px"
                    style={{ marginTop: 26 }}
                    component="h4"
                  >
                    {t("goal")}
                  </Title>
                  <Stack
                    justifyContent="center"
                    style={{
                      width: "100%",
                      height: 21,
                      marginTop: 16,
                      position: "relative",
                    }}
                  >
                    <Text fontSize={18} lineHeight="21px" fontWeight={400}>
                      {numberWithSeparators(displayedChallenge.currentAmount)}{" "}
                      {t("missions").toLowerCase()}
                    </Text>
                    <Text
                      fontSize={18}
                      lineHeight="21px"
                      fontWeight={700}
                      style={{ position: "absolute", right: 0 }}
                    >
                      {numberWithSeparators(displayedChallenge.targetAmount)}{" "}
                      {t("missions").toLowerCase()}
                    </Text>
                  </Stack>
                  <ChallengeProgressBar
                    percentage={Math.round(
                      (displayedChallenge.currentAmount /
                        displayedChallenge.targetAmount) *
                        100
                    )}
                    challengeTargetAmount={displayedChallenge.targetAmount}
                    style={{ marginTop: 8 }}
                  />
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: 26,
                      marginTop: 22,
                      gap: 14,
                    }}
                  >
                    <img src={propicsRowImage} style={{ height: 26 }} alt="" />
                    <Text fontSize={16} fontWeight={400}>
                      {displayedChallenge.userCount} {t("partecipants")}
                    </Text>
                  </Stack>
                  <Title
                    fontSize={24}
                    lineHeight="46px"
                    style={{ marginTop: 26 }}
                    component="h4"
                  >
                    {t("description")}
                  </Title>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      height: 70,
                      gap: desktopColumnsGap,
                      marginTop: 16,
                    }}
                  >
                    <Stack
                      alignItems="center"
                      style={{
                        width: "50%",
                        height: "100%",
                        borderRadius: 10,
                        backgroundColor: colors.background,
                        paddingTop: 11,
                        gap: 4,
                      }}
                    >
                      <Text fontSize={16}>{t("starts")}</Text>
                      <Text fontSize={18} fontWeight={700}>
                        {`${new Date(
                          displayedChallenge.startDate
                        ).getDate()} ${t(
                          months[
                            new Date(displayedChallenge.startDate).getMonth()
                          ]
                        ).toLowerCase()} ${new Date(
                          displayedChallenge.startDate
                        ).getFullYear()}`}
                      </Text>
                    </Stack>
                    <Stack
                      alignItems="center"
                      style={{
                        width: "50%",
                        height: "100%",
                        borderRadius: 10,
                        backgroundColor: colors.background,
                        paddingTop: 11,
                        gap: 4,
                      }}
                    >
                      <Text fontSize={16}>{t("ends")}</Text>
                      <Text fontSize={18} fontWeight={700}>
                        {`${new Date(displayedChallenge.endDate).getDate()} ${t(
                          months[
                            new Date(displayedChallenge.endDate).getMonth()
                          ]
                        ).toLowerCase()} ${new Date(
                          displayedChallenge.endDate
                        ).getFullYear()}`}
                      </Text>
                    </Stack>
                  </Stack>
                  <div
                    className="html"
                    dangerouslySetInnerHTML={{
                      __html: displayedChallenge.description.replace(
                        "https://drive.google.com/file/d/1q-1ytdiEuZMg79TePO9WGbX2lrIByQMq/view",
                        contestRules
                      ),
                    }}
                    style={{
                      fontSize: 20,
                      fontWeight: 300,
                      lineHeight: "32px",
                      color: colors.text,
                      overflow: "hidden",
                      marginTop: 20,
                    }}
                  />
                  {displayedSavings &&
                  (displayedSavings.co2Saving ||
                    displayedSavings.waterSaving ||
                    displayedSavings.energySaving) ? (
                    <Title
                      fontSize={24}
                      lineHeight="46px"
                      style={{ marginTop: 26 }}
                      component="h4"
                    >
                      {t("community_savings")}
                    </Title>
                  ) : null}
                  {displayedSavings &&
                  (displayedSavings.co2Saving ||
                    displayedSavings.waterSaving ||
                    displayedSavings.energySaving) ? (
                    <Stack
                      direction="row"
                      className="hide-scrollbars"
                      gap={2}
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        overflowX: "scroll",
                        marginTop: 16,
                      }}
                    >
                      {displayedSavings.co2Saving ? (
                        <SavingCard
                          metric="co2"
                          amount={displayedSavings.co2Saving}
                        />
                      ) : null}
                      {displayedSavings.waterSaving ? (
                        <SavingCard
                          metric="water"
                          amount={displayedSavings.waterSaving}
                        />
                      ) : null}
                      {displayedSavings.energySaving ? (
                        <SavingCard
                          metric="energy"
                          amount={displayedSavings.energySaving}
                        />
                      ) : null}
                    </Stack>
                  ) : null}
                  <Button
                    title={t("contribute_to_the_challenge")}
                    width="100%"
                    disabled={isChallengeOver || isOldChallenge}
                    style={{ marginTop: 42 }}
                    onClick={() => {
                      scrollWindowToTop()
                      navigate("/")
                      setTimeout(() => {
                        scrollWindow(780, "smooth")
                      }, 250)
                    }}
                  >
                    {isOldChallenge
                      ? t("challenge_ended")
                      : isChallengeOver
                      ? t("challenge_ended")
                      : t("contribute_to_the_challenge")}
                  </Button>
                </Stack>
                {!isChallengeOver && (
                  <ButtonBase
                    aria-label={
                      isOldChallenge
                        ? t("view_active_challenge")
                        : t("view_past_challenge")
                    }
                    disabled={displayedLeaderboardLoading}
                    disableRipple
                    style={{ width: "fit-content", marginTop: 20 }}
                    onClick={() => {
                      setIsOldChallenge((current) => !current)
                    }}
                  >
                    <Title
                      fontSize={20}
                      color={colors.primary}
                      style={{ textDecoration: "underline" }}
                    >
                      {isOldChallenge
                        ? t("view_active_challenge")
                        : t("view_past_challenge")}
                    </Title>
                  </ButtonBase>
                )}
              </Stack>
            </AppearFromSide>
            {/* side content */}
            <AppearFromSide
              fade
              delay={0.1}
              x={100}
              style={{ width: "40%", height: "auto", marginTop: 350 }}
            >
              {/* prizes */}
              <Stack
                style={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ position: "relative" }}
                >
                  <Title
                    fontSize={26}
                    color={colors.primary}
                    style={{ fontStyle: "italic" }}
                  >
                    {t("prizes").toUpperCase()}
                  </Title>
                </Stack>
                <Stack direction="row" style={{ marginTop: 16, gap: 20 }}>
                  {(isOldChallenge ? oldPrizes : prizes)
                    .filter((prize) => !prize.draw)
                    .map((prize) => (
                      <Stack
                        key={prize.title}
                        style={{
                          width: 200,
                          height: 287,
                          borderRadius: 12,
                          boxShadow: defaultBoxShadow,
                          backgroundColor: colors.backgroundWhite,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: 150,
                            backgroundImage: `url(${prize.image})`,
                            backgroundSize: "122%",
                            backgroundPosition: "70%",
                            position: "relative",
                          }}
                        >
                          <div
                            className="center"
                            style={{
                              width: "fit-content",
                              height: 25,
                              backgroundColor: colors.primary,
                              borderRadius: 22,
                              paddingInline: 11,
                              paddingTop: 3,
                              position: "absolute",
                              left: 15,
                              bottom: 13,
                            }}
                          >
                            <Text
                              fontSize={14}
                              fontWeight={500}
                              color={colors.textWhite}
                            >
                              {prize.heading}
                            </Text>
                          </div>
                        </div>
                        <Stack style={{ padding: 16, gap: 12 }}>
                          <Title
                            fontSize={18}
                            lineHeight="25px"
                            style={{ height: 75 }}
                          >
                            {prize.title}
                          </Title>
                          <Title fontSize={14} fontWeight={400}>
                            {prize.value}
                          </Title>
                        </Stack>
                      </Stack>
                    ))}
                </Stack>
                {(isOldChallenge ? oldPrizes : prizes).some(
                  (prize) => prize.draw
                ) && (
                  <Stack style={{ marginTop: 16, gap: 16 }}>
                    {(isOldChallenge ? oldPrizes : prizes)
                      .filter((prize) => prize.draw)
                      .map((prize, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          alignItems="center"
                          style={{
                            backgroundColor: colors.primary,
                            padding: 20,
                            borderRadius: 12,
                            gap: 12,
                          }}
                        >
                          <img
                            src={prize.image}
                            style={{
                              width: 65,
                              minWidth: 65,
                              height: 59,
                              objectFit: "cover",
                              borderRadius: 6,
                            }}
                            alt=""
                          />
                          <Stack style={{ gap: 4 }}>
                            <div
                              className="center"
                              style={{
                                width: "fit-content",
                                height: 25,
                                backgroundColor: colors.backgroundWhite,
                                borderRadius: 22,
                                paddingInline: 11,
                                paddingTop: 3,
                              }}
                            >
                              <Text
                                fontSize={14}
                                fontWeight={500}
                                color={colors.primary}
                              >
                                {prize.heading}
                              </Text>
                            </div>
                            <Title fontSize={14} color={colors.textWhite}>
                              {prize.title}
                            </Title>
                          </Stack>
                        </Stack>
                      ))}
                  </Stack>
                )}
              </Stack>
              {/* leaderboard */}
              {isChallengeOver ? (
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: 66,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ width: "100%", height: 36 }}
                  >
                    <Title
                      fontSize={26}
                      color={colors.primary}
                      style={{ fontStyle: "italic" }}
                    >
                      {t("winners").toUpperCase()}
                    </Title>
                  </Stack>
                  <div style={{ position: "relative" }}>
                    <Stack
                      style={{
                        width: "100%",
                        maxHeight: 424,
                        marginTop: 16,
                        gap: 16,
                        overflowY: "auto",
                      }}
                      onScroll={async (e) => {
                        if (
                          e.currentTarget.scrollTop >=
                            e.currentTarget.scrollHeight - 500 &&
                          nextToken.current &&
                          !loadingRef.current &&
                          !leaderboard.some((item) => item.position > 5)
                        ) {
                          loadingRef.current = true
                          setLoading(true)

                          await getLeaderboard(false)

                          loadingRef.current = false
                          setLoading(false)
                        }
                      }}
                    >
                      {leaderboard.length ? (
                        leaderboard
                          .filter((item) => item.position <= 3)
                          .map((item, index) => (
                            <LeaderboardListItemCard
                              key={index}
                              place={item.position}
                              name={`${item.first_name} ${item.last_name}`}
                              points={item.points}
                              isUser={item.sub === user!.sub}
                              profileImage={item.profileImage}
                            />
                          ))
                      ) : (
                        <Text fontSize={18} fontWeight={400}>
                          {t("leaderboard_empty_state")}.
                        </Text>
                      )}
                    </Stack>
                    {loading && (
                      <LinearProgress
                        style={{
                          width: "100%",
                          borderRadius: 14,
                          position: "absolute",
                          bottom: 0,
                        }}
                      />
                    )}
                  </div>
                </Stack>
              ) : (
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: 66,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ width: "100%", height: 36, position: "relative" }}
                  >
                    <Title
                      fontSize={26}
                      color={colors.primary}
                      style={{ fontStyle: "italic" }}
                    >
                      {isOldChallenge
                        ? t("winners").toUpperCase()
                        : t("leaderboard").toUpperCase() + "*"}
                    </Title>
                    {leaderboard.length > 5 && !displayedLeaderboardLoading ? (
                      <ButtonBase
                        style={{ position: "absolute", right: 0, bottom: 3 }}
                        aria-label={t("see_all")}
                        onClick={() => {
                          scrollWindowToTop()
                          navigate("/performance/leaderboard")
                        }}
                      >
                        <Text
                          fontSize={16}
                          fontWeight={700}
                          color={colors.primary}
                          style={{ textDecoration: "underline" }}
                        >
                          {t("see_all")}
                        </Text>
                      </ButtonBase>
                    ) : null}
                  </Stack>
                  {displayedLeaderboardLoading ? (
                    <Stack style={{ width: "100%", marginTop: 16, gap: 16 }}>
                      <Skeleton
                        variant="rectangular"
                        style={{ width: "100%", height: 72, borderRadius: 10 }}
                      />
                      <Skeleton
                        variant="rectangular"
                        style={{ width: "100%", height: 72, borderRadius: 10 }}
                      />
                      <Skeleton
                        variant="rectangular"
                        style={{ width: "100%", height: 72, borderRadius: 10 }}
                      />
                      <Skeleton
                        variant="rectangular"
                        style={{ width: "100%", height: 72, borderRadius: 10 }}
                      />
                      <Skeleton
                        variant="rectangular"
                        style={{ width: "100%", height: 72, borderRadius: 10 }}
                      />
                    </Stack>
                  ) : leaderboardError ? (
                    <Stack gap={3} style={{ marginTop: 16 }}>
                      <Text fontSize={18} fontWeight={400}>
                        {t("leaderboard_error")}.
                      </Text>
                      <Button
                        title={t("retry")}
                        width={170}
                        style={{ height: 36, alignSelf: "center" }}
                        onClick={() => {
                          getLeaderboard(false, false)
                        }}
                      >
                        {t("retry")}
                      </Button>
                    </Stack>
                  ) : (
                    <Stack style={{ width: "100%", marginTop: 16, gap: 16 }}>
                      {leaderboard.length ? (
                        leaderboard
                          .slice(0, 5)
                          .map((item, index) => (
                            <LeaderboardListItemCard
                              key={index}
                              place={item.position}
                              name={`${item.first_name} ${item.last_name}`}
                              points={item.points}
                              isUser={item.sub === user!.sub}
                              profileImage={item.profileImage}
                            />
                          ))
                      ) : (
                        <Text fontSize={18} fontWeight={400}>
                          {t("leaderboard_empty_state")}.
                        </Text>
                      )}
                    </Stack>
                  )}
                  {!isOldChallenge && (
                    <Text
                      fontSize={14}
                      fontWeight={400}
                      style={{ marginTop: 26 }}
                    >
                      *Tra i partecipanti a parimerito, si terrà conto
                      dell’ordine cronologico di raggiungimento punti. Per
                      maggiori info vedi{" "}
                      <span
                        role="button"
                        style={{
                          fontWeight: 700,
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          window.open(contestRules)
                        }}
                      >
                        regolamento
                      </span>
                      .
                    </Text>
                  )}
                </Stack>
              )}
            </AppearFromSide>
          </div>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={colors.background}
          style={{ position: "absolute", top: 900 }}
        />
      </div>
      {/* challenge image */}
      <img
        src={displayedChallenge.image}
        style={{
          width: "100%",
          height: 337,
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          top: 335,
        }}
        alt=""
        aria-hidden="true"
      />
      {/* alerts */}
      <AlertInfo
        open={challengeInfoAlertOpen}
        setOpen={setChallengeInfoAlertOpen}
        title={t("what_is_the_challenge")}
        description={t("challenge_description_no_html", {
          count: displayedChallenge.targetAmount,
        })}
        descriptionAlign="center"
      />
    </Stack>
  )
}

const LeaderboardListItemCard = ({
  place,
  name,
  points,
  isUser,
  profileImage,
}: {
  place: number
  name: string
  points: number
  isUser: boolean
  profileImage?: string
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: 72,
        minHeight: 72,
        backgroundColor: colors.backgroundWhite,
        boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <LeaderboardListItem
        place={place}
        name={name}
        points={points}
        isUser={isUser}
        profileImage={profileImage}
      />
    </div>
  )
}

export default ChallengeDesktop
