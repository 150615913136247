import { useContext, useEffect, useMemo, useState } from "react"
import LandingMobile from "../../components/landing/LandingMobile"
import { MainContext } from "../../controllers/main"
import LandingDesktop from "../../components/landing/LandingDesktop"
import { registerPageEvent } from "../../services/utils/utils"
import LandingDesktopRecap from "../../components/landing/LandingDesktopRecap"
import { t } from "i18next"
import Prize from "../../models/prize"
import prize1Image from "../../assets/images/prizes/prize-1.jpg"
import { UsersContext } from "../../controllers/users"

const Landing = () => {
  const { isMobile, isChallengeOver, user } = useContext(MainContext)
  const { leaderboard } = useContext(UsersContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("landing")
  }, [])

  // array of prizes
  const prizes: Prize[] = useMemo(
    () => [
      {
        heading: `1° - 3° ${t("in_ranking").toLowerCase()}`,
        title: "Un weekend per 2 persone allo Chalet Levissima 3000*",
        image: prize1Image,
        value: "Valore € 1.000 ciascuno",
      },
      {
        heading: "1 premio ad estrazione",
        title: "Un weekend per 2 persone allo Chalet Levissima 3000*",
        image: prize1Image,
        value: "Valore € 1.000",
        draw: true,
      },
    ],
    []
  )

  // challenge recap feedback
  const userPosition = useMemo(() => {
    if (!user) {
      return 999
    }

    if (!leaderboard.some((item) => item.sub === user.sub)) {
      return 999
    }

    return leaderboard.find((item) => item.sub === user.sub)!.position
  }, [user, leaderboard])

  const [recapFeedbackOpen, setRecapFeedbackOpen] = useState<boolean>(
    userPosition <= 3 &&
      !localStorage.getItem("recapFeedbackSecondChallenge") &&
      isChallengeOver
      ? true
      : false
  )

  return isMobile ? (
    <LandingMobile
      prizes={prizes}
      recapFeedbackOpen={recapFeedbackOpen}
      setRecapFeedbackOpen={setRecapFeedbackOpen}
      userPosition={userPosition}
    />
  ) : isChallengeOver ? (
    <LandingDesktopRecap
      prizes={prizes}
      recapFeedbackOpen={recapFeedbackOpen}
      setRecapFeedbackOpen={setRecapFeedbackOpen}
      userPosition={userPosition}
    />
  ) : (
    <LandingDesktop prizes={prizes} />
  )
}

export default Landing
